import React, { useState } from 'react';
import "./downloads.css"
import { downloads } from './downloadsData';

const Downloads = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };
  
    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const downloadsArray = downloads.data

    const downloadElements = downloadsArray.map((downloadElement) => {
        return (
            <li key={downloadElement.id} className="download-link-wrapper">
                <a className="download-link" target='' href={downloadElement.link}>{downloadElement.name}</a>
            </li>
        )
    })


  return (
    <div className="dropdown"  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="dropdown-trigger">
        Downloads
      </div>
          <ul className={"dropdown-menu " + (isOpen ? "active" : "inactive")}>
              {downloadElements}
        </ul>
    </div>
  );
};

export default Downloads;
