import "./careers.css"
import React, { useState } from 'react';
import { careers } from "../../assets/careers/careersData";

export default function Careers() {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };
  
    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const careersArray = careers.data

    const careerElements = careersArray.map((careerElement) => {
        return (
            <li key={careerElement.key} className="download-link-wrapper">
                <a className="download-link" target='' href={careerElement.path}>{careerElement.file_name}</a>
            </li>
        )

    })

  return (
    <div className="dropdown"  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="dropdown-trigger">
        Careers
      </div>
          <ul className={"dropdown-menu " + (isOpen ? "active" : "inactive")}>
              {careerElements}
        </ul>
    </div>
  );
};