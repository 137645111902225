// App.js
import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Staffing from './pages/staffing/Staffing';
import Home from './pages/home/Home';
import Consulting from './pages/consulting/Consulting';
import ContactForm from './components/contactForm/contactForm';

function App() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  return (
    <Router>
      <div className="App">
        <Navbar  handleClick={toggleModal}/>
        <main>
          <Routes>
            <Route path="/staffing" element={<Staffing />} />
            <Route path="/consulting" element={<Consulting />} />
            <Route path='/' element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </main>
        {showModal ? <ContactForm handleClick={toggleModal} /> : ""}

      </div>
    </Router>
  );
}

export default App;
