import React from "react";
import "./consulting.css";

export default function Consulting() {
    return (
        <div className="staffing">
            <div className="page-header">
                <h1 className="header-title letter-spacing">Consulting</h1>
            </div>

            <div className="text-area">
                <h2 className="service-subtitle staffing-subtitle">
                    Our Methodology
                </h2>
                <p className="service-text ta-justify consulting-margin">
                    At Pillar South Africa, we are firmly committed to supporting the growth and success of your organization. Our Human Resources (HR) services are central to our consulting offerings, providing essential HR solutions tailored to your unique needs.

                    Our HR services cover a comprehensive range, from talent acquisition to performance management, all designed to align with your specific requirements. We're here to assist you in every aspect of HR management.
                    Going beyond traditional HR services, our HR Rescue service is available for companies facing HR challenges or aiming to optimize their HR functions. We recognize the significance of HR compliance and completeness, and we ensure that your HR operations are both comprehensive and fully compliant.

                    Our support services are crafted to meet your needs wherever and whenever you require assistance, whether it's managing payroll, ensuring compliance, or providing regulatory guidance. We're committed to making your journey smoother by adapting our services to your specific requirements. With Pillar South Africa, you have a steadfast ally in achieving your organization's goals.

                    Explore our consulting page to discover more about how we can assist in your organization's growth and stability.

                </p>

                <h2 className="service-subtitle staffing-subtitle">
                    Our Comprehensive HR Services:
                </h2>
                <p className="service-text ta-justify consulting-margin">
                    Our consulting services are a testament to our commitment to being a versatile and adaptive partner. We understand that each organization is unique, and its journey to success is as distinctive as its goals. Our comprehensive HR services encompass the full spectrum of Human Resources, including:
                </p>

                <div className="service-text staffing-faq-grid consulting-list consulting-margn">
                    <div className="staffing-faq-grid-item"><div className="faq-icon talent-icon"></div><p className="staffing-list-item"><strong>Talent Acquisition: </strong><br /><br /> Our tailored recruitment strategies attract top-tier professionals, aligning their skills and experience with your organization's specific requirements.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon performance-icon"></div><p className="staffing-list-item"><strong>Performance Management: </strong><br /><br />We help fuel continuous improvement and employee development through our performance management solutions, enhancing overall productivity.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon hr-compliance-icon"></div><p className="staffing-list-item"><strong>HR Compliance: </strong><br /><br />In today's complex regulatory landscape, HR compliance is non-negotiable. Our team is well-versed in industry-specific regulations and employment laws, ensuring that your HR operations are not only comprehensive but also meticulously compliant.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon rescue-icon"></div><p className="staffing-list-item"><strong>HR Rescue Services:</strong><br /><br /> For businesses facing HR challenges or those seeking to optimize their HR functions, our HR Rescue service is your lifeline. We're here to address HR issues promptly and comprehensively, helping you navigate complex HR challenges with ease.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon payroll-icon"></div><p className="staffing-list-item"><strong>Payroll Management:</strong><br /><br /> Our payroll management services ensure that your employees are compensated accurately and on time, reducing administrative burdens and ensuring a smooth payroll process.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon compliance-icon"></div><p className="staffing-list-item"><strong>Compliance Assurance: </strong><br /><br />We offer meticulous compliance checks and assistance to guarantee that your HR operations align with relevant regulations, reducing the risk of legal issues.</p></div>
                    <div className="staffing-faq-grid-item"><div className="faq-icon regulatory-icon"></div><p className="staffing-list-item"><strong>Regulatory Guidance:</strong><br /><br /> Our experts provide guidance and insights to navigate the intricacies of your specific industry, ensuring your HR operations remain in full compliance with industry standards.</p></div>
                </div>

            </div>

        </div> 
    )
}