import About from "../../components/About";
import Hero from "../../components/Hero";
import Quote from "../../components/Quote";


export default function Home() {
    return (
        <>
            <Hero />
            {/* <Quote /> */}
            <About />
            {/* <Footer /> */}
      </>
    )
}