import file0 from "./Bookkeeper with Tender Process Exposure.pdf"
import file2 from "./DC Systems Engineer.pdf"
import file3 from "./Document Controller.pdf"
import file5 from "./Electrical Engineers.pdf"
import file6 from "./Finanace Manager.pdf"
import file7 from "./FMCG Sales Manager.pdf"
import file8 from "./FMCG Sales Representative.pdf"
import file9 from "./H3C Certified Network Professional.pdf"
import file10 from "./ICT Enterprise Architecture Consultant.pdf"
import file11 from "./ICT Governance and Security Consultants.pdf"
import file12 from "./ISP Sales Specialist Consultant.pdf"
import file13 from "./Media Communications Internship.pdf"
import file14 from "./Office Adminstrator.pdf"
import file15 from "./Power Systems Engineer.pdf"
import file16 from "./Procurement Specialist.pdf"
import file17 from "./Salesperson with BEE Verification Industry Experience.pdf"
import file18 from "./SCADA Systems Engineer.pdf"
import file19 from "./Security Systems Engineer.pdf"
import file20 from "./Service Desk and Application Analysts.pdf"
import file21 from "./Site Engineer.pdf"
import file22 from "./SOC Analyst.pdf"
import file23 from "./Telecomms Network Engineer.pdf"
import file24 from "./Tender Administrator.pdf"

export const careers = {
  data: [
    {
      "file_name": "Bookkeeper with Tender Process Exposure.pdf",
      "key": 0,
      "path": file0
    },
    {
      "file_name": "DC Systems Engineer.pdf",
      "key": 2,
      "path": file2
    },
    {
      "file_name": "Document Controller.pdf",
      "key": 3,
      "path": file3
    },
    {
      "file_name": "Electrical Engineers.pdf",
      "key": 5,
      "path": file5
    },
    {
      "file_name": "Finanace Manager.pdf",
      "key": 6,
      "path": file6
    },
    {
      "file_name": "FMCG Sales Manager.pdf",
      "key": 7,
      "path": file7
    },
    {
      "file_name": "FMCG Sales Representative.pdf",
      "key": 8,
      "path": file8
    },
    {
      "file_name": "H3C Certified Network Professional.pdf",
      "key": 9,
      "path": file9
    },
    {
      "file_name": "ICT Enterprise Architecture Consultant.pdf",
      "key": 10,
      "path": file10
    },
    {
      "file_name": "ICT Governance and Security Consultants.pdf",
      "key": 11,
      "path": file11
    },
    {
      "file_name": "ISP Sales Specialist Consultant.pdf",
      "key": 12,
      "path": file12
    },
    {
      "file_name": "Media Communications Internship.pdf",
      "key": 13,
      "path": file13
    },
    {
      "file_name": "Office Adminstrator.pdf",
      "key": 14,
      "path": file14
    },
    {
      "file_name": "Power Systems Engineer.pdf",
      "key": 15,
      "path": file15
    },
    {
      "file_name": "Procurement Specialist.pdf",
      "key": 16,
      "path": file16
    },
    {
      "file_name": "Salesperson with BEE Verification Industry Experience.pdf",
      "key": 17,
      "path": file17
    },
    {
      "file_name": "SCADA Systems Engineer.pdf",
      "key": 18,
      "path": file18
    },
    {
      "file_name": "Security Systems Engineer.pdf",
      "key": 19,
      "path": file19
    },
    {
      "file_name": "Service Desk and Application Analysts.pdf",
      "key": 20,
      "path": file20
    },
    {
      "file_name": "Site Engineer.pdf",
      "key": 21,
      "path": file21
    },
    {
      "file_name": "SOC Analyst.pdf",
      "key": 22,
      "path": file22
    },
    {
      "file_name": "Telecomms Network Engineer.pdf",
      "key": 23,
      "path": file23
    },
    {
      "file_name": "Tender Administrator.pdf",
      "key": 24,
      "path": file24
    }
  ]
}
