import link1 from "../../assets/downloads/BEE.pdf"
import link2 from "../../assets/downloads/Pillar South Africa Privacy Policy.pdf"

export const downloads = {
    data: [{
        id: 1,
        name: "BEE Certificate",
        link: link1
    }, {
        id: 1,
        name: "Pillar South Africa Privacy Policy",
        link: link2
    }]
}